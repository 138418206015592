<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <p>大师权利与义务</p>
        <div class="questionList">
            <div class="question">
                <p>1、大师享受的权利？</p>
                <div class="arrowDiv" @click="arrow(0)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>1、若您是[某某]大师，您发布[某某]预测的时候，彩友是需要付太阳才能看。自己查看自己的预测是不需要太阳的，直接点击“查看心水”->“查看”。</p>
                    <p>2、预测心水收益分成比例大概是6:4分成(大师60%，平台40%)，平台的“大师包月”功能不提供分成，关于预测心水的具体描述详见下文。</p>
                    <p>3、成为大师期间，系统会免费为您开通打赏功能，打赏收益是5:5分成</p>
                    <p>4、大师种类不同，预测心水收益也不一样</p>
                    <p>芝麻：  8太阳 大师收益得5</p>
                    <p>三定：  6太阳 大师收益得4</p>
                    <p>二定：  5太阳 大师收益得3</p>
                    <p>稳码：  4太阳 大师收益得3</p>
                    <p>定位：  3太阳 大师收益得2</p>
                    <p>三字现： 3太阳 大师收益得2</p>
                    <p>二字现： 2太阳 大师收益得1</p>
                    <p>头尾合： 3太阳 大师收益得2</p>
                    <p>中肚合： 3太阳 大师收益得2</p>
                    <p>死数：  2太阳 大师收益得1</p>
                    <p>杀码  ：2太阳 大师收益得1</p>
                </div>
            </div>
            <div class="question">
                <p>2、如果自己拥有多个大师称号，怎么处理？</p>
                <div class="arrowDiv" @click="arrow(1)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>能力优秀，极有可能拥有多个大师的称号，比如既是[头尾]大师，也是[定头]大师，如何收费？</p>
                    <p>您发帖时，如果同时发表[定头]、[头尾]预测数据，粉丝看预测信息时，会按价格高的收费，假如您分开发，一个帖子发[头尾]，追加帖发[定头]，那么粉丝会付费两次才能看您的帖子，但相应您的粉丝对您的意见可能就比较大，甚至是排斥，这是需要大师您自己去衡量、选择和取舍的</p>
                </div>
            </div>
            <div class="question">
                <p>3、如何提现？</p>
                <div class="arrowDiv" @click="arrow(2)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>1、“我”->“资产”->“我的收益”->“兑换收益”(若看不到，请升级至最新版本，根据要求兑换)</p>
                    <p>2、遵守《中华人民共和国个人所得税》法，个人承担个人所得税，见《兑换服务协议》</p>
                    <p>3、个税是个人获利必须要缴纳的，就像领工资需要缴纳个税是一个道理。请您不要抱怨，平台也要承担增值税(3%)、增值附加税、企业所得税(25%)，除此之外平台还承担了第三方所有费率(2%)，还要保证平台正常运作，真的已经很不易，请珍惜在平台的发展机会。</p>
                </div>
            </div>
            <div class="question">
                <p>4、大师应履行的义务？</p>
                <div class="arrowDiv"  @click="arrow(3)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>1、要遵守大公鸡的协议和<span style="color:blue" @click="goPage('manage')">《彩友圈管理规范》</span></p>
                    <p><span>2、不要为了成为大师，乱填数据，开通小号等，</span>比如[定头]01234，又发表[芝麻]的千位56789，这种方式的推荐数据，[定头]数据只统计01234，而芝麻的千位56789是不会被统计的，并且若有这种情况发生，我们将删除您所有大师称号，这种不为彩民考虑的行为，彩友不欢迎，平台也不欢迎。带上“大师”的牌子，就得对得起这称号！请知悉！</p>
                    <p>3、若违反协议和规范，导致被封号，如：发自己的联系方式(QQ、微信、手机、小号代发、诱导他人发联系方式等等)，取消大师资格，永久禁言。被永久禁言的用户将无法交流与兑换收益！</p>
                    <p>4、禁言后，将冻结所有资金，解除所有荣誉称号(大师、周月榜)，大部分功能将会失效，请知悉！</p>
                </div>
            </div>
            <div class="question">
                <p>5、违规的处理</p>
                <div class="arrowDiv"  @click="arrow(4)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>1、若发自己的联系方式(QQ、微信、手机、小号代发、诱导他人发联系方式等等)，取消大师资格，永久禁言！</p>
                    <p>2、在帖子评论区，不得发布自己或者其他大师预测心水，否则将取消大师资格，若您不想收费，请联系管理员，我们可以删除您的大师的称号，这样您发的帖将会免费</p>
                    <p>3、若违规被取消大师和禁言后，将冻结所有资金，解除所有荣誉称号(大师、周月榜)，部分功能将会失效！</p>
                    <p><span style="color: red;">4、不要为了成为大师，乱填数据</span>，比如[定头]01234，又发表[芝麻]的千位56789，这种方式的推荐数据，[定头]数据只统计01234，而芝麻的千位56789是不会被统计的，并且若有这种情况发生，我们将删除您的所有的大师称号，这种不为彩民考虑的行为，彩友不欢迎，平台也不欢迎。带上“大师”的牌子，就得对得起这称号！请知悉！</p>
                </div>
            </div>
            <div class="question">
                <p>6、想免费提供心水，不想收费</p>
                <div class="arrowDiv"  @click="arrow(5)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>请联系我们的客服，联系方式见下方，客服将会取消您的大师称号，今后您不再参与大师活动，发布的心水将永久免费。</p>
                </div>
            </div>
            <div class="question">
                <p>7、联系客服</p>
                <div class="arrowDiv"  @click="arrow(6)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p style="color: blue;text-decoration:underline;" @click="goPage('contact')">点击联系我们</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";

export default {
    data(){
        return{
            title:this.$route.meta.title
        }
    },
    components:{
        vHeader
    },
    methods:{
        arrow(val){
            this.direction = !this.direction
            let answers = document.getElementsByClassName('answer')
            let arrows = document.getElementsByClassName('arrow')
            for(let i=0;i<answers.length;i++){
                answers[i].style.height = 0;
            }
            answers[val].style.height = 'auto'
            
            for(let i=0;i<arrows.length;i++){
                arrows[i].className = 'arrow';
            }
            arrows[val].className = 'arrow change-direction'
            if(!this.direction){
                answers[val].style.height = 0
                arrows[val].className = 'arrow';
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        height: auto;
        position: absolute;
        background-color: #eee;
        top: 0;
        p{
            font-size: 16px;
            padding-left: 6px;
            font-weight: bolder;
        }
        .questionList{
            width: 100%;
            min-height: 100px;
            border-top: 1px solid #ccc;
            background-color: #fff;
            .question{
                position: relative;
                border-bottom: 1px solid #ccc;
                p{
                    font-weight: normal;
                    min-height: 42px;
                    line-height: 32px;
                    padding: 10px 0 0 6px;
                    font-size: 18px;
                    margin: 0 10px;
                }
                .arrowDiv{
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    right: 0px;
                    top: 10px;
                    .arrow{
                        position: absolute;
                        right:8px;
                        top: 10px;
                        width: 8px;
                        height: 8px;
                        border-top: 2px solid #555;
                        border-right: 2px solid #555;
                        transform: rotate(135deg);
                        transform-origin:center;
                        transition: all 0.2s linear;
                    }
                    .change-direction{
                        transform: rotate(-45deg);
                    }
                }
                .answer{
                    background-color: #eee;
                    padding-left: 4px ;
                    color: #555;
                    overflow: hidden;
                    height: 0;
                    p{
                        padding: 0;
                        margin: 0;
                        font-size: 14px;
                        width: 100%;
                        height: auto;              
                    }
                }
            }
        }
    }
</style>